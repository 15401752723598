<template>
  <master-layout>

    <ion-card>
      <ion-card-content>
        <h1>Üdvözlünk az<br/>Országos Bortúra<br/>alkalmazásban!</h1>
        <p>
          Ezen az oldalon találod majd azoknak a borászatoknak a listáját, amelyeket már meglátogattál és ahonnan becsekkoltál.
        </p>
        <img src="assets/images/welcome-illustration.svg" class="illustration"/>
        <ion-button shape="round" expand="block" color="primary" @click="$router.push('/checkin')">Becsekkolok az első helyről!</ion-button>
        <p class="pCenter"><b>- vagy -</b></p>
        <ion-button shape="round" expand="block" color="primary" @click="$router.push('/regisztracio')">Regisztrálok</ion-button>
        <ion-button expand="block" fill="transparent" @click="$router.push('/login')">Bejelentkezem</ion-button>
      </ion-card-content>
    </ion-card>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonCardContent, IonButton
  },
  ionViewWillEnter() {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
ion-button{
  margin: 10px 0;
}
h1{
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 33px;
}
p{
  text-align: center;
}
.illustration{
  display: block;
  width: 90%;
  margin: 40px auto 20px auto;
}
</style>
